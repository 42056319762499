$primary: #3b3b3b;

@import "main";

.post-author {
    color: #9a9a9a
}

//Used on the 404 page, to center content
.error-container {
    margin: 10px auto;
    max-width: 600px;
    text-align: center;
}

//Used to fix anchor links being hidden by the sticky top bar
:target::before {
    content: '';
    display: block;
    height:      180px;
    margin-top: -180px;
}

//Disable padding on commento pre elements
#commento pre {
    padding: 0px;
}

//////////////////////////////////////////////////////////////////////////
// Documentation menubar
//////////////////////////////////////////////////////////////////////////
#documentation-menubar {
    //remove extreme padding
    margin-left: 0em;
    //remove bullet points
    list-style: none;

}

//////////////////////////////////////////////////////////////////////////
// Badges from bootstrap, don't need the full kit
//////////////////////////////////////////////////////////////////////////
.badge-danger {
    color: #fff;
    background-color: #dc3545;
}

.badge-warning {
    color: #212529;
    background-color: #ffc107;
}

.badge-info {
    color: #fff;
    background-color: #17a2b8;
}

.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

//////////////////////////////////////////////////////////////////////////
// END Badges from bootstrap, don't need the full kit
//////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////
// Social hero bar
//////////////////////////////////////////////////////////////////////////
.socials-hero {
    background: #3b3b3b;
}

.socials-desktop {
    display: inline-flex;
}
.socials-mobile {
    display: none;
}

@media only screen and (max-width: 950px) {
    .socials-desktop {
        display: none;
    }
    .socials-mobile {
        display: inline-flex;
    }
}

//////////////////////////////////////////////////////////////////////////
// END Social hero bar
//////////////////////////////////////////////////////////////////////////


//////////////////////////////////////////////////////////////////////////
// Missing Social Providers
// - Mastodon
//////////////////////////////////////////////////////////////////////////
.button.is-mastodon {
    background-color: #6364FF;
    border-color: transparent;
    color: #fff;
}

.button.is-mastodon:hover, .button.is-mastodon.is-hovered {
background-color: #563ACC;
border-color: transparent;
color: #fff;
}

//////////////////////////////////////////////////////////////////////////
// END Missing Social Providers
//////////////////////////////////////////////////////////////////////////